import { useStaticQuery, graphql } from 'gatsby';

const useBuyTicketsPage = () => {
    const { sanityPageBuyTickets: pageBuyTickets } = useStaticQuery(graphql`
        query {
            sanityPageBuyTickets {
                previewImage {
                    ...ImageWithPreview
                }
                headerImage {
                    ...ImageWithPreview
                }
                headerTitle {
                    se
                    en
                }
                headerDescription {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                ticketsInformationTitle {
                    se
                    en
                }
                ticketsInformationDescription {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                ticketsBuyingTitle {
                    se
                    en
                }
                ticketsBuyingDescription {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                headerButtonText {
                    se 
                    en
                }
                headerButtonLink
                categoriesTitle {
                    se
                    en
                }
                ticketsCategories {
                    category
                    content {
                        se {
                            _rawContent
                        }
                        en {
                            _rawContent
                        }
                    }
                }

                seasonalPassTitle {
                    se
                    en
                }
                seasonalPassDescription {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                seasonalPassPricesTitle {
                    se
                    en
                }
                menArenaTitle {
                    se
                    en
                }
                menArenaImage {
                    ...ImageWithPreview
                }
                ticketsPricesMen {
                    categoryNumber
                    categoryName
                    categoryColor
                    categoryPrice
                    categoryPriceFee
                    categoryPriceReduced
                    categoryPriceReducedFee
                }
                ticketsPricesMenFootnotes {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                womenArenaTitle {
                    se
                    en
                }
                womenArenaImage {
                    ...ImageWithPreview
                }
                ticketsPricesWomen {
                    categoryNumber
                    categoryName
                    categoryColor
                    categoryPrice
                    categoryPriceFee
                    categoryPriceReduced
                    categoryPriceReducedFee
                }
                ticketsPricesWomenFootnotes {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                faqTitle {
                    se
                    en
                }
                faqSubtitle {
                    se
                    en
                }
                faqLink {
                    se {
                        ...MenuItem
                    }
                    en {
                        ...MenuItem
                    }
                }
            }
        }
    `);

    return pageBuyTickets;
};

export default useBuyTicketsPage;
