export const MATCH_STATUS = {
    FINISHED: 'finished',
    CANCELLED: 'cancelled',
    PLANNED: 'planned',
    FIRST_PERIOD: 'firstPeriod',
    SECOND_PERIOD: 'secondPeriod',
    HALF_TIME: 'halfTime',
    EXTENSION: 'extension',
    SHOT_ON_GOAL: 'shotOnGoal',
    REPORTED: 'reported',
    INTERRUPTED: 'interrupted',
}

export const AXS_URLS = {
    MEN: 'https://hammarbyherr.ebiljett.nu/Account/AxsSdk',
    WOMEN : 'https://hammarbydam.ebiljett.nu/Account',
}

export const AXS_EMAIL_ATTRIBUTES = {
    men: 'custom:ticketsMenEmail',
    women : 'custom:ticketsWomenEmail',
}
