import React from 'react';

import useRudderStack from 'hooks/useRudderStack';

const TrackableCTA = ({ children, ctaText, location, destination }) => {
  const { track } = useRudderStack();

  const handleClick = () => {
    track('CTA Clicked', {
      ctaText,
      location,
      destination,
    });
  };

  return React.cloneElement(children, { onClick: handleClick });
};

export default TrackableCTA;
