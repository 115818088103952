// extracted by mini-css-extract-plugin
export var match = "item-module--match--b1OpX";
export var matchFinished = "item-module--matchFinished--IHhsM";
export var matchHorizontal = "item-module--matchHorizontal--olKLr";
export var matchHorizontalFinished = "item-module--matchHorizontalFinished--KDQbO";
export var matchHorizontal__center = "item-module--matchHorizontal__center--LqJz0";
export var matchHorizontal__content = "item-module--matchHorizontal__content--XkhcW";
export var matchHorizontal__contentWithButton = "item-module--matchHorizontal__contentWithButton--RXoMI";
export var matchHorizontal__league = "item-module--matchHorizontal__league--fsjgd";
export var matchHorizontal__left = "item-module--matchHorizontal__left--km4z5";
export var matchHorizontal__link = "item-module--matchHorizontal__link--cX8bp";
export var matchHorizontal__logo = "item-module--matchHorizontal__logo--KxAnp";
export var matchHorizontal__logoContainer = "item-module--matchHorizontal__logoContainer--0WHeJ";
export var matchHorizontal__logoContainer__teamTitle = "item-module--matchHorizontal__logoContainer__teamTitle--5zc9g";
export var matchHorizontal__right = "item-module--matchHorizontal__right--ObaUF";
export var matchHorizontal__score = "item-module--matchHorizontal__score--zKj81";
export var matchHorizontal__scoreHammarby = "item-module--matchHorizontal__scoreHammarby--l3xSl";
export var matchHorizontal__scores = "item-module--matchHorizontal__scores--QfoO5";
export var matchHorizontal__scores__scoreContainer = "item-module--matchHorizontal__scores__scoreContainer--WUGuz";
export var match__bottom = "item-module--match__bottom--TlxiC";
export var match__link = "item-module--match__link--Kyc1r";
export var match__loc = "item-module--match__loc--O+65I";
export var match__logos = "item-module--match__logos--D4POy";
export var match__middle = "item-module--match__middle--qXx8N";
export var match__names = "item-module--match__names--y3k0u";
export var match__names__separator = "item-module--match__names__separator--IIBFL";