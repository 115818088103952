import React, { useContext } from 'react';
// import SlickSlider from 'react-slick';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as styleTickets from 'styles/components/buyTickets/buyTickets.module.scss';
import useBuyTicketsPage from 'data/queries/useBuyTicketsPage';
import SeasonalPassCategory from 'components/buyticket/SeasonalPassCategory';
import LinkFromMenuItem from 'components/utils/LinkFromMenuItem';
import TextEditorRender from 'components/utils/TextEditorRender';
import Slider from 'components/utils/Slider';
import MatchList from 'components/buyticket/TicketsList';
import TicketsBanner from 'components/buyticket/TicketsBanner';
import { LangContext } from 'context/LangContext';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const BuyTickets = ({ pageContext: { matches, teams, showResults } }) => {
    const { lang } = useContext(LangContext);
    const data = useBuyTicketsPage();

    const [currentSection, setCurrentSection] = React.useState(null);
    const refTickets = React.useRef();
    const refTicketsInformation = React.useRef();
    const sefSeasonal = React.useRef();
    const refFaq = React.useRef();
    const handleClick = (id) => {
        window.scrollTo(0, id.current.offsetTop - 100);
    };

    let bannerDescription = null;
    let bannerButtonText = null;
    let bannerButtonLink = null;

    if( data.headerDescription ) {
        bannerDescription = data?.headerDescription[lang.translationKey]?._rawContent;
    }

    if( data.headerButtonText ) {
        bannerButtonText = data.headerButtonText[lang.hreflang];
    }

    if( data.headerButtonLink ) {
        bannerButtonLink = data.headerButtonLink;
    }

    React.useEffect(() => {
        const SECTIONS = [refTickets, refTicketsInformation, sefSeasonal, refFaq];
        const handleScroll = () => {
            // eslint-disable-next-line no-plusplus
            for (let i = SECTIONS.length - 1; i >= 0; i--) {
                if (window.scrollY + 200 > SECTIONS[i].current.offsetTop) {
                    setCurrentSection(SECTIONS[i]);
                    return;
                }
            }
            setCurrentSection(null);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            {data.headerImage && data.headerTitle && (
                <TicketsBanner
                    image={data.headerImage}
                    title={data.headerTitle[lang.hreflang]}
                    description={bannerDescription}
                    ctaText={bannerButtonText}
                    ctaUrl={bannerButtonLink}
                    manageText={MESSAGES[lang.translationKey].buytickets.manageTickets}
                />
            )}
            <nav className={`${styleTickets.nav} bg`}>
                <ul>
                    {[
                        {
                            id: '#biljetter',
                            text: MESSAGES[lang.translationKey].buytickets.tickets,
                            ref: refTickets,
                        },
                        {
                            id: '#biljettinformation',
                            text: MESSAGES[lang.translationKey].buytickets.ticketInformation,
                            ref: refTicketsInformation,
                        },
                        {
                            id: '#sasongskort',
                            text: MESSAGES[lang.translationKey].buytickets.seasonalPass,
                            ref: sefSeasonal,
                        },
                        {
                            id: '#faq',
                            text: MESSAGES[lang.translationKey].buytickets.faq,
                            ref: refFaq,
                        },
                    ].map((item) => (
                        <li key={item.id}>
                            <a
                                href={item.id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClick(item.ref);
                                }}
                                className={`${currentSection === item.ref ? 'active' : ''} text-md`}
                            >
                                {item.text}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
            <section className={`${styleTickets.buyTicketsPage__container} wrapper`} id="biljetter" ref={refTickets}>
                <MatchList matches={matches} teams={teams} showResults={showResults} />
            </section>

            <section className={`${styleTickets.buyTicketsPage__container} wrapper`} id="biljettinformation" ref={refTicketsInformation}>
                <h2 className="title-xs-mobile title-sm text-semibold">
                    {data.ticketsInformationTitle[lang.hreflang]}
                </h2>
                <div className={styleTickets.buyTicketsPage__ticketsColumns}>
                    <div className={styleTickets.buyTicketsPage__ticketsColumn}>
                        <TextEditorRender content={data.ticketsInformationDescription[lang.translationKey]._rawContent} />
                    </div>
                    <div className={styleTickets.buyTicketsPage__infoBanners}>
                        <div className={styleTickets.buyTicketsPage__infoBanner}>
                            <h3 className="title-xs text-semibold">Har du några frågor?</h3>
                            <div className="richtext">
                                <p>
                                    <a href="/faq">Läs i FAQ</a>
                                </p>
                                <p>
                                    <span>Kontakta oss:</span><br/>
                                    <a href="mailto:biljetter@hammarbyfotboll.se">biljetter@hammarbyfotboll.se</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${styleTickets.buyTicketsPage__container} wrapper`}>
                <h2 className="title-xs-mobile title-sm text-semibold">
                    {data.ticketsBuyingTitle[lang.hreflang]}
                </h2>
                <div className={styleTickets.buyTicketsPage__ticketsColumns}>
                    <div className={styleTickets.buyTicketsPage__ticketsColumn}>
                        <TextEditorRender content={data.ticketsBuyingDescription[lang.translationKey]._rawContent} />
                    </div>
                    <div className={styleTickets.buyTicketsPage__infoBanners}>
                        <div className={styleTickets.buyTicketsPage__infoBanner}>
                            <h3 className="title-xs text-semibold">Hjälp med biljetter</h3>
                            <div className="richtext">
                                <p>
                                    <span>AXS kundsupport:</span><br/>
                                    <a href="tel:0771454600">0771 45 46 00</a>
                                </p>
                                <p>
                                    <a href="https://support.axs.com/hc/sv/articles/360018283540-Hur-kontaktar-jag-AXS-Sverige-" target="_blank" rel="noreferrer">Andra alternativ</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${styleTickets.buyTicketsPage__container} wrapper`}>
                <div className={`${styleTickets.slider__categoryContainer} block`}>

                    <Slider
                        title={data.categoriesTitle[lang.translationKey]}
                        slidesDesktop={2}
                        slidesTablet={1}
                        slidesMob={1}
                    >
                        {data.ticketsCategories.map((item, index) => (
                            <div key={index}>
                                <div className={styleTickets.slider__categoryBlock}>
                                    <div className={styleTickets.slider__categoryBlockContent}>
                                        <h3 className="title-xs text-semibold">{item.category}</h3>
                                        <TextEditorRender content={item.content[lang.translationKey]._rawContent} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                </div>
            </section>
            <section className={`${styleTickets.buyTicketsPage__container} wrapper`} id="sasongskort" ref={sefSeasonal}>
                <div className="text-center">
                    <h2 className={`${styleTickets.buyTicketsPage__seasonalTitle} title-sm-mobile title-lg text-semibold`}>
                        {data.seasonalPassTitle[lang.hreflang]}
                    </h2>
                </div>
                <div className={styleTickets.buyTicketsPage__ticketsColumns}>
                    <div className={styleTickets.buyTicketsPage__ticketsColumn}>
                        <TextEditorRender content={data.seasonalPassDescription[lang.translationKey]._rawContent} />
                    </div>
                    <div className={styleTickets.buyTicketsPage__infoBanners}>
                        <div className={styleTickets.buyTicketsPage__infoBanner}>
                            <h3 className="title-xs text-semibold">Hjälp med säsongskort</h3>
                            <div className="richtext">
                                <p>
                                Växeln för nedan telefonnummer är endast öppet under säsongskortsperioden.
                                </p>
                                <p>
                                    <span>Telefon:</span><br/>
                                    <a href="tel:084628810">08-462 88 13</a>
                                </p>
                                <p>
                                    <span>Mail:</span><br/>
                                    <a href="mailto:biljetter@hammarbyfotboll.se">biljetter@hammarbyfotboll.se</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styleTickets.buyTicketsPage__seasonalPassInfo}>
                    <h2 className="title-xs-mobile title-sm text-semibold">
                        {data.seasonalPassPricesTitle[lang.hreflang]}
                    </h2>

                    <h3 className="text-lg text-semibold">
                        {data.menArenaTitle[lang.hreflang]}
                    </h3>
                    <div className={`${styleTickets.buyTicketsPage__arenaOverview} bg`}>
                        <ImageGatsby
                            {...data.menArenaImage}
                            alt={data.menArenaTitle[lang.hreflang]}
                        />
                    </div>
                    <div className={styleTickets.buyTicketsPage__pricingContainer}>
                        <ul>
                            <li key="t01" className={styleTickets.buyTicketsPage__pricingHeader}>
                                <div className={styleTickets.buyTicketsPage__titleRow}>
                                    {MESSAGES[lang.translationKey].buytickets.category}
                                </div>
                                <div className={styleTickets.buyTicketsPage__price}>
                                    {MESSAGES[lang.translationKey].buytickets.price}
                                </div>
                                <div className={styleTickets.buyTicketsPage__priceReduced}>
                                    {MESSAGES[lang.translationKey].buytickets.priceReduced}
                                </div>
                            </li>
                            {data.ticketsPricesMen
                                // .filter(ticketCategory => ticketCategory.language.hreflang === lang.translationKey)
                                .map(ticketCategory => (
                                    <li key={`${ticketCategory.categoryNumber}m`}>
                                        <SeasonalPassCategory category={ticketCategory} />
                                    </li>
                                ))
                            }
                        </ul>
                        <div className={styleTickets.buyTicketsPage__footnotes}>
                            <TextEditorRender content={data.ticketsPricesMenFootnotes[lang.translationKey]._rawContent} />
                        </div>
                    </div>

                    <h3 className="text-lg text-semibold">
                        {data.womenArenaTitle[lang.hreflang]}
                    </h3>
                    <div className={`${styleTickets.buyTicketsPage__arenaOverview} bg`}>
                        <ImageGatsby
                            {...data.womenArenaImage}
                            alt={data.womenArenaTitle[lang.hreflang]}
                        />
                    </div>
                    <div className={styleTickets.buyTicketsPage__pricingContainer}>
                        <ul>
                            <li key="t02" className={styleTickets.buyTicketsPage__pricingHeader}>
                                <div className={styleTickets.buyTicketsPage__titleRow}>
                                    {MESSAGES[lang.translationKey].buytickets.category}
                                </div>
                                <div className={styleTickets.buyTicketsPage__price}>
                                    {MESSAGES[lang.translationKey].buytickets.price}
                                </div>
                                <div className={styleTickets.buyTicketsPage__priceReduced}>
                                    {MESSAGES[lang.translationKey].buytickets.priceReduced}
                                </div>
                            </li>
                            {data.ticketsPricesWomen
                                // .filter(ticketCategory => ticketCategory.language.hreflang === lang.translationKey)
                                .map(ticketCategory => (
                                    <li key={`${ticketCategory.categoryNumber}w`}>
                                        <SeasonalPassCategory category={ticketCategory} />
                                    </li>
                                ))
                            }
                        </ul>
                        <div className={styleTickets.buyTicketsPage__footnotes}>
                            <TextEditorRender content={data.ticketsPricesWomenFootnotes[lang.translationKey]._rawContent} />
                        </div>
                    </div>
                </div>
            </section>

            <section className={styleTickets.faq} id="faq" ref={refFaq}>
                <h2 className="title-lg title-sm-mobile bold text-center">
                    {data.faqTitle[lang.translationKey]}
                </h2>

                <p className={styleTickets.faq__subtitle}>{data.faqSubtitle[lang.translationKey]}</p>

                <LinkFromMenuItem item={data.faqLink[lang.translationKey]} cssClass="cta-yellow" />
            </section>
        </div>
    )
};
export default BuyTickets;
