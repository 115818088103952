import React, {  } from 'react';

import * as style from 'styles/components/buyTickets/seasonalPassCategory.module.scss';

// import * as style from 'styles/components/news/item.module.scss';
// import { LangContext } from 'context/LangContext';
// import { RoutesContext } from 'context/RoutesContext';

const SeasonalPassCategory = ({ category }) => {

    const priceOrdinary = category.categoryPrice ? category.categoryPrice : "-";
    const priceReduced = category.categoryPriceReduced ? category.categoryPriceReduced : "-";

    return (
        <div className={style.seasonalPassCategory}>
            <div className={style.seasonalPassCategory__titleRow}>
                <div className={style.categoryNumber} style={{ backgroundColor: category.categoryColor }}>{category.categoryNumber}</div>
                <div>{category.categoryName}</div>
            </div>
            <div className={style.seasonalPassCategory__price}>{priceOrdinary}</div>
            <div className={style.seasonalPassCategory__priceReduced}>{priceReduced}</div>
        </div>
    );
};

export default SeasonalPassCategory;
