import { useContext, useEffect } from 'react';
import * as React from 'react';
import SlickSlider from 'react-slick';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/utils/slider.module.scss';

import { breakpoints } from '../../data/utils';

import CustomLink from './CustomLink';
import FormattedMessage, { getFormattedMessage } from './FormattedMessage';

const Slider = ({
    title,
    link,
    linkNamedRoute,
    linkText,
    slidesDesktop = 4,
    slidesTablet = 2,
    slidesMob = 1,
    customResponsive,
    hasVariableWidth = false,
    hasWrapper = true,
    hasOverflow = true,
    children,
    swipeCallback = () => {},
    needRefresh = false,
}) => {
    const { lang } = useContext(LangContext);

    let slickSlider = {};
    let slidesToShow = slidesMob;

    if (typeof window !== 'undefined') {
        if (window.innerWidth > breakpoints.medium) {
            slidesToShow = slidesDesktop;
        } else if (window.innerWidth > breakpoints.small) {
            slidesToShow = slidesTablet;
        }
    }
    const [atStart, setAtStart] = React.useState(true);
    const [atEnd, setAtEnd] = React.useState(children.length <= slidesToShow);

    // Fix a slick bug that do not reset correctly the slider position when changing the slider content
    useEffect(() => {
        if (needRefresh) {
            slickSlider.slickPrev();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    if (children.length === 0) {
        return null;
    }

    const sliderSettings = {
        slidesToShow: slidesDesktop,
        slidesToScroll: slidesDesktop,
        infinite: false,
        arrows: false,
        variableWidth: hasVariableWidth,
        afterChange: (slide) => {
            setAtStart(slide === 0);
            setAtEnd(slide === children.length - slidesToShow);
        },
        responsive: (() => {
            if (customResponsive) {
                return customResponsive;
            }
            return [
                {
                    breakpoint: breakpoints.medium,
                    settings: {
                        slidesToShow: slidesTablet,
                        slidesToScroll: slidesTablet,
                    },
                },
                {
                    breakpoint: breakpoints.small,
                    settings: {
                        slidesToShow: slidesMob,
                        slidesToScroll: slidesMob,
                    },
                },
            ];
        })(),
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const RenderLink = () => {
        if (linkText) {
            if (link) {
                return (
                    <a href={link} className="cta-yellow-ter">
                        {linkText}
                    </a>
                );
            }

            if (linkNamedRoute) {
                return (
                    <CustomLink
                        className="cta-yellow-ter"
                        namedRoute={linkNamedRoute}
                        target="_self"
                    >
                        {linkText}
                    </CustomLink>
                );
            }
        }
        return null;
    };

    return (
        <div
            className={`${style.globalSlider} ${hasOverflow ? style.globalSliderhasOverflow : ''}`}
        >
            <div className={hasWrapper ? 'wrapper' : ''}>
                <div className={style.globalSlider__header}>
                    <h2 className="title-sm title-xs-mobile text-semibold">{title}</h2>
                    <div className="hidden-mobile">
                        <RenderLink />
                        {
                            // Disable arrows if not enough items
                            !(atStart && atEnd) && (
                                <>
                                    <button
                                        className={`btn-reset ${style.globalSlider__prev} ${
                                            atStart ? 'inactive' : ''
                                        }`}
                                        onClick={() => {
                                            if (!atStart) {
                                                slickSlider.slickPrev();
                                                swipeCallback();
                                            }
                                        }}
                                        type="button"
                                        aria-label={getFormattedMessage('global.prev', lang)}
                                    >
                                        <i className="ico-chevron" aria-hidden="true" />
                                        <span className="visually-hidden">
                                            <FormattedMessage id="global.prev" />
                                        </span>
                                    </button>
                                    <button
                                        className={`btn-reset ${style.globalSlider__next} ${
                                            atEnd ? 'inactive' : ''
                                        }`}
                                        onClick={() => {
                                            if (!atEnd) {
                                                slickSlider.slickNext();
                                                swipeCallback();
                                            }
                                        }}
                                        type="button"
                                        aria-label={getFormattedMessage('global.next', lang)}
                                    >
                                        <i className="ico-chevron" aria-hidden="true" />
                                        <span className="visually-hidden">
                                            <FormattedMessage id="global.next" />
                                        </span>
                                    </button>
                                </>
                            )
                        }
                    </div>
                </div>
                <SlickSlider
                    /* eslint-disable-next-line no-return-assign */
                    ref={(c) => (slickSlider = c)}
                    {...sliderSettings}
                    className={style.globalSlider__content}
                >
                    {children}
                </SlickSlider>
                <div className={`${style.globalSlider__footer} center hidden-desktop`}>
                    <RenderLink />
                </div>
            </div>
        </div>
    );
};

export default Slider;
