// extracted by mini-css-extract-plugin
export var academy = "buyTickets-module--academy--WZqNo";
export var buyTicketsPage__arenaOverview = "buyTickets-module--buyTicketsPage__arenaOverview--mX+Rq";
export var buyTicketsPage__container = "buyTickets-module--buyTicketsPage__container--y-UR6";
export var buyTicketsPage__footnotes = "buyTickets-module--buyTicketsPage__footnotes--tBZY3";
export var buyTicketsPage__infoBanner = "buyTickets-module--buyTicketsPage__infoBanner--iQat6";
export var buyTicketsPage__infoBanners = "buyTickets-module--buyTicketsPage__infoBanners--b7JJU";
export var buyTicketsPage__price = "buyTickets-module--buyTicketsPage__price--4kg97";
export var buyTicketsPage__priceReduced = "buyTickets-module--buyTicketsPage__priceReduced--47eSU";
export var buyTicketsPage__pricingContainer = "buyTickets-module--buyTicketsPage__pricingContainer--Zka7h";
export var buyTicketsPage__pricingHeader = "buyTickets-module--buyTicketsPage__pricingHeader--+zFgR";
export var buyTicketsPage__seasonalPassInfo = "buyTickets-module--buyTicketsPage__seasonalPassInfo--VwWl1";
export var buyTicketsPage__seasonalTitle = "buyTickets-module--buyTicketsPage__seasonalTitle--m3NcP";
export var buyTicketsPage__ticketsColumn = "buyTickets-module--buyTicketsPage__ticketsColumn--ieXKd";
export var buyTicketsPage__ticketsColumns = "buyTickets-module--buyTicketsPage__ticketsColumns--Ywe6O";
export var buyTicketsPage__titleRow = "buyTickets-module--buyTicketsPage__titleRow--NE0w7";
export var faq = "buyTickets-module--faq--if1-f";
export var faq__subtitle = "buyTickets-module--faq__subtitle--jA2Zt";
export var history = "buyTickets-module--history--Thxl8";
export var history__content = "buyTickets-module--history__content--L-dTV";
export var history__header = "buyTickets-module--history__header--XRr7W";
export var history__next = "buyTickets-module--history__next--OFnhR";
export var history__prev = "buyTickets-module--history__prev--Xguig";
export var history__slide = "buyTickets-module--history__slide--5IJIC";
export var history__slider = "buyTickets-module--history__slider--697Y-";
export var history__years = "buyTickets-module--history__years--ICrbO";
export var mob = "buyTickets-module--mob--kRtL+";
export var nav = "buyTickets-module--nav--AXJ7W";
export var orga = "buyTickets-module--orga--MzgJ4";
export var orga__wrapper = "buyTickets-module--orga__wrapper--Cll-e";
export var slider__categoryBlock = "buyTickets-module--slider__categoryBlock--90X9v";
export var slider__categoryBlockContent = "buyTickets-module--slider__categoryBlockContent--U7Zov";
export var slider__categoryContainer = "buyTickets-module--slider__categoryContainer--zRfbj";
export var structure = "buyTickets-module--structure--jyebL";
export var structure__list = "buyTickets-module--structure__list--dQTVe";
export var values = "buyTickets-module--values--L0TQE";
export var work = "buyTickets-module--work--zEZqx";