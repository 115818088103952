import React, { useContext, useEffect, useState } from 'react';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/match/list.module.scss';
import { MATCH_STATUS } from 'data/constants';
import MatchItem from 'components/match/Item';
import useHammarbyClub from 'data/queries/useHammarbyClub';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const TicketsList = ({ matches, showResults: InitialShowResults }) => {
    const { lang } = useContext(LangContext);
    const club = useHammarbyClub();

    const [filteredTeam, setFilteredTeam] = useState('');
    // Show results according to "showResults" passed from router
    const [showResults] = useState(InitialShowResults);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setFilteredTeam(urlParams.get('selectedTeam') !== 'null' ? urlParams.get('selectedTeam') : null);
    }, [club, lang.translationKey]);

    // const TOP_FILTERS_LIMIT = 8;
    const TOP_LIST_LIMIT = 0;

    // when the team is filter is null we take the 2 first teams only (men and women)
    const results = () =>
        matches
            .filter((match) => match.matchStatus === MATCH_STATUS.FINISHED)
            .filter((item) => !filteredTeam
                ? item.team.id === club.teams[0].id || item.team.id === club.teams[1].id
                : item.team.id === filteredTeam,
            )
            .sort((a, b) => new Date(a.startEventDate) - new Date(b.startEventDate));

    const upcomingMatches = () =>
        matches
            .filter((match) => match.matchStatus !== MATCH_STATUS.FINISHED)
            .filter((item) => !filteredTeam
                ? item.team.id === club.teams[0].id || item.team.id === club.teams[1].id || item.teamExternal.id === club.teams[0].id || item.teamExternal.id === club.teams[1].id
                : item.team.id === filteredTeam || item.teamExternal.id === filteredTeam,
            )
            .sort((a, b) => new Date(a.startEventDate) - new Date(b.startEventDate));

    return (
        <div>
            <div>
                    <ul className="tabs tabs--NoScrollDesk">
                        <li key="all">
                            <button
                                onClick={() => setFilteredTeam(null)}
                                className={filteredTeam === null ? 'active' : ''}
                                type="button"
                                aria-label={getFormattedMessage('global.allTeams', lang)}
                            >
                                <FormattedMessage id="global.allTeams" />
                            </button>
                        </li>
                        {club.teams
                            .filter(
                                (item) =>
                                    item.title &&
                                    item.id !== '-f6c61a57-978b-5846-afc5-5fa7de467400' &&
                                    item.id !== '-64dbeffe-0036-5248-a466-fbb46cc8c4ad' &&
                                    item.title[lang.translationKey] &&
                                    item.playerpage_visible === true,
                            )
                            .map((item, index) => (
                                <li key={index}>
                                    <button
                                        onClick={() => setFilteredTeam(item.id)}
                                        className={filteredTeam === item.id ? 'active' : ''}
                                        type="button"
                                    >
                                        {item.title[lang.translationKey]}
                                    </button>
                                </li>
                            ))}
                    </ul>
                    <h2 className="title-xs-mobile title-sm text-semibold">
                        {MESSAGES[lang.translationKey].buytickets.tickets}
                    </h2>

                    {showResults ? (
                        <ul className={style.matchList__content__list}>
                            {results().length > 0 ? (
                                results().map((item, index) => (
                                    <li key={index}>
                                        <MatchItem match={item} isHorizontalFormat />
                                    </li>
                                ))
                            ) : (
                                <li>
                                    <p>
                                        <FormattedMessage id="match.noResults" />
                                    </p>
                                </li>
                            )}
                        </ul>
                    ) : (
                        <ul className={style.matchList__content__list}>
                            {results().length > 0 &&
                                results()
                                    .slice(0, TOP_LIST_LIMIT)
                                    .map((item, index) => (
                                        <li key={index}>
                                            <MatchItem match={item} isHorizontalFormat />
                                        </li>
                                    ))}
                            {upcomingMatches().length > 0 ? (
                                upcomingMatches().map((item, index) => (
                                    <li key={index}>
                                        <MatchItem match={item} isHorizontalFormat />
                                    </li>
                                ))
                            ) : (
                                <li>
                                    <p>
                                        <FormattedMessage id="match.noMatches" />
                                    </p>
                                </li>
                            )}
                        </ul>
                    )}
            </div>
        </div>
    );
};

export default TicketsList;
